import classnames from 'classnames'
import styles from './ProductHeroDetails.module.scss'
import ContentMask from '@/components/ContentMask/ContentMask'
import { useEffect, useRef } from 'react'
import useInView from '@/hooks/use-in-view'
import gsap from 'gsap'

const ProductHeroDetails = ({
  className,
  description,
  model,
  dimensions,
  variants,
  activeVariantIndex,
  placeOfOrigin,
  setActiveVariantIndex,
}) => {
  const descriptionRef = useRef()
  const materialListRef = useRef()
  const { setElementToObserve: setDescriptionToObserve, isInView: descriptionInView } = useInView()
  const { setElementToObserve: setMaterialListToObserve, isInView: materialListInView } = useInView()

  useEffect(() => {
    if (!descriptionInView) return

    if (descriptionRef.current) {
      gsap.to(descriptionRef.current, {
        autoAlpha: 1,
        duration: 0.8,
        delay: 0.2,
      })
    }
  }, [descriptionInView])

  useEffect(() => {
    if (!materialListInView) return

    if (materialListRef.current) {
      gsap.to(materialListRef.current, {
        autoAlpha: 1,
        duration: 0.8,
        delay: 0.2,
      })
    }
  }, [materialListInView])

  return (
    <div
      ref={ref => setDescriptionToObserve(ref)}
      className={classnames(styles.ProductHeroDetails, className)}
    >
      <p
        data-themed="color"
        className={styles.aboutTitle}
      >
        (About_Product)
      </p>
      {description && (
        <p
          className={styles.description}
          ref={descriptionRef}
          data-themed="color"
        >
          {description}
        </p>
      )}
      <div className={styles.specsContent}>
        <Spec
          type="(Model)"
          label={model}
        />
        <Spec
          type="(Dimensions)"
          label={dimensions}
        />
        <Spec
          type="(Lead Time)"
          label={variants[activeVariantIndex]?.leadTime}
        />
        <Spec
          type="(Place of Origin)"
          label={placeOfOrigin}
        />
        <Spec type="(Materials)">
          <div
            className={styles.spec__materialTitles}
            ref={ref => {
              setMaterialListToObserve(ref)
              materialListRef.current = ref
            }}
          >
            {variants.map((variant, i) => (
              <button
                key={i}
                className={classnames(styles.spec__materialTitle, { [styles.isActive]: activeVariantIndex === i })}
                onClick={() => {
                  setActiveVariantIndex(i)
                }}
              >
                {variant.material}
              </button>
            ))}
          </div>
        </Spec>
        <Spec
          type="(Weight)"
          label={variants[activeVariantIndex]?.weight}
        />
        <Spec
          type="(Designed by)"
          label="Monolith Studio"
        />
      </div>
    </div>
  )
}

const Spec = ({ className, type, label, children }) => {
  const lineRef = useRef()

  if (!type) return null

  return (
    <div className={classnames(styles.spec, className)}>
      <span
        className={styles.spec__line}
        ref={lineRef}
        data-themed="background-color"
      />
      <p className={styles.spec__type}>
        <ContentMask
          element="span"
          animateInView
          text={type}
          onInView={() => {
            gsap.killTweensOf(lineRef.current)
            gsap.to(lineRef.current, {
              scaleX: 1,
              duration: 1.2,
              ease: 'Power3.easeOut',
            })
          }}
        />
      </p>
      {children && !label && <>{children}</>}
      {label && !children && (
        <p className={styles.label}>
          <ContentMask
            element="span"
            animateInView
            text={label}
          />
        </p>
      )}
    </div>
  )
}

ProductHeroDetails.displayName = 'ProductHeroDetails'

export default ProductHeroDetails
