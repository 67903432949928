import classnames from 'classnames'
import styles from './ProductsInfiniteCarousel.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import { getCropHeightFromWidth, getCropOptions, getImageBackgroundFromAsset, getUrlFromPageType, lerp } from '@/utils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Flip } from 'gsap/dist/Flip'
import { Draggable } from 'gsap/dist/Draggable'
import gsap from 'gsap'
import useInView from '@/hooks/use-in-view'
import useWindowResize from '@/hooks/use-window-resize'
import { getProductIndexString } from '@/sections/ProductGrid/DefaultGridView/DefaultGridView'
import Link from '@/components/Link/Link'
import { DOC_TYPES } from '@/data'
import ImageReveal from '@/components/ImageReveal/ImageReveal'
import useBreakpoint, { getIsMobile } from '@/hooks/use-breakpoint'
import { useRouter } from 'next/router'

gsap.registerPlugin(Flip)
gsap.registerPlugin(Draggable)

const ProductsInfiniteCarousel = ({ className, titleLeft, titleRight, products }) => {
  const _products = useMemo(() => [...products, ...products], [products])
  const productInnerRefs = useRef([])
  const productRefs = useRef([])
  const positionRefs = useRef([])
  const productIndexTextRefs = useRef([])
  const productTitleTextRefs = useRef([])
  const rafRef = useRef()
  const { setElementToObserve, isInView } = useInView({
    fireOnce: false,
    scrolltriggerStart: 'top-=400px bottom',
    scrolltriggerEnd: 'bottom+=200px top',
  })
  const calculationsRef = useRef({})
  const largeBoxRef = useRef()
  const smallBoxRef = useRef()
  const resizeKey = useWindowResize()
  const startingPositions = useRef({})
  const dragDistanceTarget = useRef(0)
  const dragDistanceCurrent = useRef(0)
  const dragDistanceActiveTarget = useRef(0)
  const dragDistanceActiveCurrent = useRef(0)
  const draggableRef = useRef(null)
  const productTrackRef = useRef()
  const productTrackItemListRef = useRef()
  const prevActiveIndexRef = useRef(null)
  const [activeIndex, setActiveIndex] = useState(null)
  const titleDebounce = useRef()
  const router = useRouter()
  const { isMobile } = useBreakpoint()

  const initialAlignmentAndCalculations = useCallback(
    callback => {
      const largeWidth = largeBoxRef.current.offsetWidth
      const largeWidthHalf = largeWidth * 0.5
      const largeToSmallScale = largeBoxRef.current.offsetWidth / smallBoxRef.current.offsetWidth
      const smallWidth = smallBoxRef.current.offsetWidth
      const smallWidthHalf = smallBoxRef.current.offsetWidth * 0.5
      const centerOffset = window.innerWidth / 2
      let gap = parseInt(gsap.getProperty(productRefs.current[0], 'paddingLeft')) * largeToSmallScale * 2.25 // For some reason this works?

      if (getIsMobile(window.innerWidth)) {
        gap = parseInt(gsap.getProperty(productRefs.current[0], 'paddingLeft')) * largeToSmallScale * 1.55 // For some reason this works?
      }

      const translateDistance = smallWidth * largeToSmallScale * 0.25 - gap
      const totalListWidth = smallWidth * _products.length
      const rediculousStartingPoint = totalListWidth * 10000
      dragDistanceTarget.current = rediculousStartingPoint
      dragDistanceCurrent.current = rediculousStartingPoint

      calculationsRef.current = {
        translateDistance,
        largeWidthHalf,
        smallWidthHalf,
        smallWidth,
        centerOffset,
        largeWidth,
        largeToSmallScale,
        gap,
        totalListWidth,
      }

      productRefs.current.forEach((element, i) => {
        const positionElement = positionRefs.current[i]
        if (!positionElement || !element) return

        Flip.fit(element, positionElement, {
          duration: 0.1,
          scale: true,
          onComplete: () => {
            startingPositions.current[i] = parseInt(gsap.getProperty(element, 'x'))
            if (callback) callback()
          },
        })
      })
    },
    [_products],
  )

  const initRaf = useCallback(initialFrameOnly => {
    if (!initialFrameOnly) {
      rafRef.current = requestAnimationFrame(() => {
        initRaf(false)
      })
    }

    dragDistanceCurrent.current = lerp(dragDistanceCurrent.current, dragDistanceTarget.current, 0.1)
    dragDistanceActiveCurrent.current = lerp(dragDistanceActiveCurrent.current, dragDistanceActiveTarget.current, 0.1)

    productRefs.current.forEach((element, i) => {
      const innerElement = productInnerRefs.current[i]
      if (!element || !innerElement) return

      const x = startingPositions.current[i] + dragDistanceCurrent.current + dragDistanceActiveCurrent.current

      gsap.set(element, {
        // x: x % calculationsRef.current.totalListWidth,
        x: x % calculationsRef.current.totalListWidth,
        // x,
      })

      const offsetLeft = element.getBoundingClientRect().left
      const differenceFromCenter =
        offsetLeft + calculationsRef.current.smallWidthHalf - calculationsRef.current.centerOffset
      const differenceNormalized = gsap.utils.clamp(
        -1,
        1,
        differenceFromCenter /
          (calculationsRef.current.translateDistance +
            calculationsRef.current.largeWidthHalf -
            calculationsRef.current.gap),
      )

      const scalingAddition = calculationsRef.current.largeToSmallScale - 1
      const scale = 1 + (1 - Math.abs(differenceNormalized)) * scalingAddition

      if (Math.abs(differenceNormalized) < 0.5 && prevActiveIndexRef.current !== i) {
        prevActiveIndexRef.current = i
        setActiveIndex(prevActiveIndexRef.current)
      }

      gsap.set(innerElement, {
        x: differenceNormalized * calculationsRef.current.translateDistance,
        scale,
      })
    })
  }, [])

  const killRaf = () => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current)
      rafRef.current = null
    }
  }

  useEffect(() => {
    initialAlignmentAndCalculations(() => {
      killRaf()

      if (draggableRef.current?.length) {
        draggableRef.current.forEach(ref => {
          ref.kill()
        })
      }

      productRefs.current = productRefs.current.filter(ref => ref)

      if (!productRefs.current.length) return

      draggableRef.current = Draggable.create(productRefs.current, {
        type: 'x',
        bounds: {
          minX: 0,
          maxX: 0,
        },
        edgeResistance: 0.2,
        onDrag() {
          dragDistanceActiveTarget.current = this.x
        },
        onDragEnd() {
          dragDistanceTarget.current = gsap.utils.snap(
            calculationsRef.current.smallWidth,
            dragDistanceTarget.current + dragDistanceActiveTarget.current,
          )
          dragDistanceActiveTarget.current = 0
        },
      })

      initRaf(true)

      if (isInView) {
        initRaf(false)
      }
    })

    return () => {
      if (rafRef.current) cancelAnimationFrame(rafRef.current)
    }
  }, [initRaf, isInView, resizeKey, initialAlignmentAndCalculations])

  useEffect(() => {
    if (!productIndexTextRefs.current.length || !productTitleTextRefs.current.length) {
      return
    }

    if (activeIndex === null) return

    if (titleDebounce.current) {
      clearTimeout(titleDebounce.current)
    }

    const duration = 0.8

    titleDebounce.current = setTimeout(
      () => {
        gsap.killTweensOf([...productIndexTextRefs.current, ...productTitleTextRefs.current])
        productIndexTextRefs.current.forEach((element, i) => {
          const titleElement = productTitleTextRefs.current[i]
          if (!element || !titleElement) return
          const isActive = i === activeIndex
          gsap.to([element, titleElement], {
            y: isActive ? 0 : '105%',
            duration: isActive ? duration : duration * 0.5,
            delay: isActive ? duration * 0.3 : 0,
            ease: 'Power3.easeOut',
          })
        })
      },
      duration * 1000 * 0.5,
    )
  }, [activeIndex])

  return (
    <div
      ref={ref => {
        setElementToObserve(ref)
      }}
      className={classnames(styles.ProductsInfiniteCarousel, className, {
        [styles.isEvenNumber]: _products.length % 2 === 0,
      })}
    >
      <div className={styles.inner}>
        <div className={styles.titlesContainer}>
          <p
            className={styles.titleLeft}
            data-themed="color"
          >
            {titleLeft}
          </p>
          <p
            className={styles.titleRight}
            data-themed="color"
          >
            {titleRight}
          </p>
          <div className={styles.productTitles}>
            {_products.map((product, i) => {
              return (
                <div
                  className={styles.productTitleContainer}
                  key={i}
                >
                  <p
                    className={styles.productTitleContainer__index}
                    data-themed="color"
                  >
                    <span ref={ref => (productIndexTextRefs.current[i] = ref)}>{getProductIndexString(i)}</span>
                  </p>
                  <p
                    className={styles.productTitleContainer__title}
                    data-themed="color"
                  >
                    <span ref={ref => (productTitleTextRefs.current[i] = ref)}>{product.title}</span>
                  </p>
                </div>
              )
            })}
          </div>
        </div>
        <div
          className={styles.productTrack}
          ref={productTrackRef}
        >
          <div
            className={styles.boxPositionSmall}
            ref={smallBoxRef}
          />
          <div
            className={styles.boxPositionLarge}
            ref={largeBoxRef}
          />
          <div
            className={styles.productTrackItemList}
            ref={productTrackItemListRef}
          >
            {Array(_products.length)
              .fill(null)
              .map((_, i) => (
                <div
                  key={i}
                  className={styles.trackPosition}
                  ref={ref => {
                    positionRefs.current[i] = ref
                  }}
                />
              ))}
            {_products.map((product, i) => {
              const firstVariant = product?.productData?.variants[0]

              if (!firstVariant) return null

              return (
                <div
                  className={styles.productContainer}
                  ref={ref => {
                    productRefs.current[i] = ref
                  }}
                  key={i}
                >
                  <div
                    className={styles.productContainer__inner}
                    ref={ref => {
                      productInnerRefs.current[i] = ref
                    }}
                    onClick={() => {
                      router.push(
                        {
                          pathname: getUrlFromPageType(DOC_TYPES.PRODUCT, product.slug.current),
                        },
                        undefined,
                        { scroll: false },
                      )
                    }}
                  >
                    <Link
                      link={{
                        linkType: 'internal',
                        link: {
                          _id: 'anyString',
                          _type: DOC_TYPES.PRODUCT,
                          slug: product.slug.current,
                        },
                      }}
                      className={styles.link}
                    >
                      Go to product
                    </Link>
                    <ImageReveal
                      backgroundColor={getImageBackgroundFromAsset(firstVariant.image)}
                      className={styles.imageReveal}
                    >
                      {isMobile !== null && (
                        <SanityImage
                          image={firstVariant.image}
                          className={styles.productContainer__image}
                          width={isMobile ? 122 : 413}
                          height={isMobile ? 171 : 570}
                          breakpoints={{
                            tablet: {
                              width: 900,
                              image: firstVariant.image,
                              options: getCropOptions(product?.productData?.imageOrientation, 'portrait', {
                                height: getCropHeightFromWidth('portrait', 900),
                              }),
                            },
                            xs: {
                              width: 240,
                              image: firstVariant.image,
                              options: getCropOptions(product?.productData?.imageOrientation, 'portrait', {
                                height: getCropHeightFromWidth('portrait', 240),
                              }),
                            },
                          }}
                        />
                      )}
                    </ImageReveal>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

ProductsInfiniteCarousel.displayName = 'ProductsInfiniteCarousel'

export default ProductsInfiniteCarousel
